<template>
  <div class="cpEventBox" :class="guardFlag == 0 && tabFlag == 1 ? 'bottomPadding' : ''" @click="changeDateSelect(2)" @scroll="pageScroll">
     <header class="headBg">
        <div class="rulesBtn" @click="rulesPopChange(5)">{{ $t('valentine_rewards_button') }}</div>
        <p v-if="actStatus == 1">{{ $t('valentine_time_endin') }} </p>
        <p v-if="actStatus == 0">{{ $t('valentine_time_startin') }}</p>
        <p v-if="actStatus == 2" style="padding-top: 0.2rem">{{ $t('valentine_time_ended') }}</p>
        <p v-if="actStatus != 2"><span>{{ this.days }}</span> {{ $t('valentine_time_days') }} <span>{{ this.hours}}</span> {{ $t('valentine_time_hours') }} <span>{{ this.minutes }}</span> {{ $t('valentine_time_minutes') }} <span>{{ this.seconds }}</span> {{ $t('valentine_time_seconds') }}</p>
     </header>
     <div class="giftBox">
      <header>{{ $t('valentine_gift') }}</header>
      <ul class="giftItemBox">
        <li class="giftItem">
          <div>
            <img src="../../assets/cpEvent/gift1.png" alt="">
          </div>
          <p>{{ $t('valentine_gift_1') }}</p>
          <p>+80</p>
        </li>
        <li class="giftItem">
          <div>
            <img src="../../assets/cpEvent/gift2.png" alt="">
          </div>
          <p>{{ $t('valentine_gift_2') }}</p>
          <p>+8,000</p>
        </li><li class="giftItem">
          <div>
            <img src="../../assets/cpEvent/gift3.png" alt="">
          </div>
          <p>{{ $t('valentine_gift_3') }}</p>
          <p>+40,000</p>
        </li><li class="giftItem">
          <div>
            <img src="../../assets/cpEvent/gift4.png" alt="">
          </div>
          <p>{{ $t('valentine_gift_4') }}</p>
          <p>+80,000</p>
        </li>
      </ul>
     </div>
     <!-- tab开始 -->
     <div class="tabBox">
      <p :class="tabFlag == 0 ? 'tabBtnAct' : ''" @click="changeTab(0)">{{ $t('valentine_tab1') }}</p>
      <p :class="tabFlag == 1 ? 'tabBtnAct' : ''" @click="changeTab(1)">{{ $t('valentine_tab2') }}</p>
     </div>
     <div>
      <!-- 旅程 -->
      <div class="cpBox" v-show="tabFlag == 0">
        <div class="topCpShow">
            <header></header>
            <div class="topCpInfoBox" v-if="showCpInfo">
              <span class="cpRules" @click="rulesPopChange(3)"></span>
              <div class="leftUserBox">
                <div class="userIconBox" @click="skipUserInfo(showCpInfo.user1)">
                  <img v-if="showCpInfo.user1.headIcon" :src="showCpInfo.user1.headIcon" alt="">
                  <img v-else src="../../assets/cpEvent/userHeadNull.png" alt="">
                </div>
                <p class="userName" v-if="showCpInfo.user1">{{ showCpInfo.user1.name }}</p>
              </div>
              <div class="centerContent">
                <div class="centerHeart"></div>
                <div class="cpNum">{{ showCpInfo.score | formatNumber }}</div>
              </div>
              <div class="leftUserBox rightUserBox">
                <div class="userIconBox" @click="skipUserInfo(showCpInfo.user2)">
                  <img v-if="showCpInfo.user2 && showCpInfo.user2.headIcon" :src="showCpInfo.user2.headIcon" alt="">
                  <img v-else src="../../assets/cpEvent/userHeadNull.png" alt="">
                  <span class="changeCp" @click.stop="rulesPopChange(2)"></span>
                </div>
                <p class="userName">{{ showCpInfo.user2 ? showCpInfo.user2.name : '' }}</p>
              </div>
            </div>
            <div class="topCpInfoBox" v-else>
              <span class="cpRules" @click="rulesPopChange(3)"></span>
              <div class="leftUserBox">
                <div class="userIconBox">
                  <img v-if="selfHeadIcon" :src="selfHeadIcon" alt="">
                  <img v-else src="../../assets/cpEvent/userHeadNull.png" alt="">
                </div>
                <p class="userName" >{{ selfName }}</p>
              </div>
              <div class="centerContent">
                <div class="centerHeart"></div>
                <div class="cpNum">--</div>
              </div>
              <div class="leftUserBox rightUserBox">
                <div class="userIconBox">
                  <img src="../../assets/cpEvent/userHeadNull.png" alt="">
                  <!-- <span class="changeCp" @click="rulesPopChange(2)"></span> -->
                </div>
                <p class="userName"></p>
              </div>
            </div>
        </div>
          <!-- 旅程图 -->
          <div class="cpMapBox">
            <header></header>
            <div class="cpMapMsg" v-if="rewards.length > 0">
              <div class="cpMapPro" :class="'cpMapPro' + curIndex"></div>
              <div class="cpMapProMsg">
                <p class="mapTitle">{{ $t('valentine_trip_intro') }}</p>
                <div class="mapPro1 mapPro">
                  <div>
                    <img :class="rewards[0].state == 1 ? 'breathing-circle' + ' breathing-circle' + rewards[0].state  : ''" @click="rulesPopChange(4,rewards[0],0)" src="../../assets/cpEvent/mapIcon1.png" alt="">
                  </div>
                  <div class="mapPro1Info">
                      <p>{{ $t('valentine_trip_step1') }}</p>
                      <p>
                        <span>{{  Number(rewards[0].limit).toLocaleString('en-US')}}</span>
                        <span>{{ $t('valentine_trip_target') }}</span>
                      </p>
                  </div>
                </div>
                <div class="mapPro1 mapPro2 mapPro" style="padding-left: 10px;">
                  <div class="mapPro1Info">
                    <p>{{ $t('valentine_trip_step2') }}</p>
                      <p>
                        <span>{{  Number(rewards[1].limit).toLocaleString('en-US')}}</span>
                        <span>{{ $t('valentine_trip_target') }}</span>
                      </p>
                  </div>
                  <div class="gift2ImgBox">
                    <img :class="rewards[1].state == 1 ? 'breathing-circle' + ' breathing-circle' + rewards[1].state : ''" @click="rulesPopChange(4,rewards[1],1)" src="../../assets/cpEvent/mapIcon2.png" alt="">
                  </div>
                </div>
                <div class="mapPro1 mapPro3 mapPro">
                  <div>
                    <img :class="rewards[2].state == 1 ? 'breathing-circle' + ' breathing-circle' + rewards[2].state : ''" @click="rulesPopChange(4,rewards[2],2)" src="../../assets/cpEvent/mapIcon3.png" alt="">
                  </div>
                  <div class="mapPro1Info">
                    <p>{{ $t('valentine_trip_step3') }}</p>
                      <p>
                        <span>{{  Number(rewards[2].limit).toLocaleString('en-US')}}</span>
                        <span>{{ $t('valentine_trip_target') }}</span>
                      </p>
                  </div>
                </div>
                <div class="mapPro1 mapPro2 mapPro4 mapPro">
                  <div class="mapPro1Info">
                    <p>{{ $t('valentine_trip_step4') }}</p>
                      <p>
                        <span>{{  Number(rewards[3].limit).toLocaleString('en-US')}}</span>
                        <span>{{ $t('valentine_trip_target') }}</span>
                      </p>
                  </div>
                  <div>
                    <img :class="rewards[3].state == 1 ? 'breathing-circle' + ' breathing-circle' + rewards[3].state : ''" @click="rulesPopChange(4,rewards[3],3)" src="../../assets/cpEvent/mapIcon4.png" alt="">
                  </div>
                </div>
                <div class="mapPro1 mapPro3 mapPro5 mapPro">
                  <div class="mapIconBox"><img :class="rewards[4].state == 1 ? 'breathing-circle' + ' breathing-circle' + rewards[4].state : ''" @click="rulesPopChange(4,rewards[4],4)" src="../../assets/cpEvent/mapIcon5.png" alt=""></div>
                  <div class="mapPro1Info">
                    <p>{{ $t('valentine_trip_step5') }}</p>
                      <p>
                        <span>{{  Number(rewards[4].limit).toLocaleString('en-US')}}</span>
                        <span>{{ $t('valentine_trip_target') }}</span>
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- 守护 -->
      <div class="guardBox" v-show="tabFlag == 1">
        <div class="guardTabBox">
          <div class="guardTab">
            <p :class="guardFlag == 0 ? 'guardTabAct' : ''" @click="changeGuardFlag(0)">{{ $t('valentine_tab3') }}</p>
            <p :class="guardFlag == 1 ? 'guardTabAct' : ''" @click="changeGuardFlag(1)">{{ $t('valentine_tab4') }}</p>
          </div>
          <span class="rulse2Btn" @click="rulesPopChange(1)"></span>
        </div>
        <div class="roundRankBox" v-show="guardFlag == 0">
          <div class="roundTimeBox">
            <div class="roundTime" :class="dateSelectFlag == 1 ?'bottomRadius':''" @click.stop="changeDateSelect(1)">{{ actDay | insertSlashInMiddle }}</div>
            <ul class="roundTimeList" v-if="dateSelectFlag == 1" >
              <li v-for="item in actTimeArr" :key="item" @click.stop="changeDateSelect(0,item)">{{ item | insertSlashInMiddle}}</li>
            </ul>
          </div>
          <p class="roundDownTime" v-if="nowDay && actStatus != 2">{{ $t('valentine_tab3_timecount') }} <span>{{dayDownTime}}</span></p>
          <p class="roundDownTime" v-else>{{ $t('valentine_tab3_finished') }}</p>
          <ul class="roundRankList" >
            <template v-if="rankListData.length > 0">
              <li v-for="(item,index) in rankListData" :key="index">
                <div class="leftUserBox">
                  <div class="userIconBox" @click="skipUserInfo(item.user1)">
                    <img v-if="item.user1.headIcon" :src="item.user1.headIcon" alt="" >
                    <img v-else src="../../assets/cpEvent/userHeadNull.png" alt="">
                    <img class="toukuang" v-if="item.user1 && item.user1.frameMinUrl" :src="item.user1.frameMinUrl" alt="">
                  </div>
                  <p class="userName">{{ item.user1.name }}</p>
                  <p class="gradeIconBox">
                    <span :class="['vip', 'vip' + item.user1.vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="item.user1.vipLv && item.user1.vipLv > 0">{{ item.user1.vipLv }}</span>
                    <span v-if="item.user1 && item.user1.wealthLv > 0" :class="['wealth', countClass(item.user1.wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                      <span>{{ item.user1.wealthLv }}</span>
                    </span>
                  </p>
                </div>
                <div class="centerContent">
                  <div class="rankNum">{{ item.top }}</div>
                  <div class="centerHeart"></div>
                  <div class="cpNum">{{ item.score | formatNumber  }}</div>
                </div>
                <div class="leftUserBox rightUserBox">
                  <div class="userIconBox" @click="skipUserInfo(item.user2)">
                    <img v-if="item.user2 && item.user2.headIcon" :src="item.user2.headIcon" alt="">
                    <img v-else src="../../assets/cpEvent/userHeadNull.png" alt="">
                    <img class="toukuang" v-if="item.user2 && item.user2.frameMinUrl" :src="item.user2.frameMinUrl" alt="">
                  </div>
                  <p class="userName">{{ item.user2 ? item.user2.name : '' }}</p>
                  <p class="gradeIconBox" v-if="item.user2">
                    <span :class="['vip', 'vip' + item.user2.vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="item.user2.vipLv && item.user2.vipLv > 0">{{ item.user2.vipLv }}</span>
                    <span v-if="item.user2 && item.user2.wealthLv > 0" :class="['wealth', countClass(item.user2.wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                      <span>{{ item.user2.wealthLv }}</span>
                    </span>
                  </p>
                </div>
              </li>
            </template>
            <template v-else>
              <li class="ranListNull"></li>
            </template>
          </ul>
          <div class="selfInfo" v-if="selfRankInfo">
            <div class="leftUserBox">
                <div class="userIconBox" @click="skipUserInfo(selfRankInfo.user1)">
                  <img v-if="selfRankInfo.user1.headIcon" :src="selfRankInfo.user1.headIcon" alt="">
                  <img v-else src="../../assets/cpEvent/userHeadNull.png" alt="">
                    <img class="toukuang" v-if="selfRankInfo.user1 && selfRankInfo.user1.frameMinUrl" :src="selfRankInfo.user1.frameMinUrl" alt="">
                </div>
                <p class="userName">{{ selfRankInfo.user1.name }}</p>
                <p class="gradeIconBox">
                  <span :class="['vip', 'vip' + selfRankInfo.user1.vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="selfRankInfo.user1.vipLv && selfRankInfo.user1.vipLv > 0">{{ selfRankInfo.user1.vipLv }}</span>
                    <span v-if="selfRankInfo.user1 && selfRankInfo.user1.wealthLv > 0" :class="['wealth', countClass(selfRankInfo.user1.wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                      <span>{{ selfRankInfo.user1.wealthLv }}</span>
                    </span>
                </p>
              </div>
              <div class="centerContent">
                <div class="rankNum">{{selfRankInfo.top == 0 ? '--' : selfRankInfo.top}}</div>
                <div class="centerHeart"></div>
                <div class="cpNum">{{ selfRankInfo.score | formatNumber }}</div>
              </div>
              <div class="leftUserBox rightUserBox">
                <div class="userIconBox" @click="skipUserInfo(selfRankInfo.user2)">
                  <img v-if="selfRankInfo.user2 && selfRankInfo.user2.headIcon" :src="selfRankInfo.user2.headIcon" alt="">
                  <img v-else src="../../assets/cpEvent/userHeadNull.png" alt="">
                    <img class="toukuang" v-if="selfRankInfo.user2 && selfRankInfo.user2.frameMinUrl" :src="selfRankInfo.user2.frameMinUrl" alt="">
                </div>
                <p class="userName" >{{ selfRankInfo.user2 ? selfRankInfo.user2.name : '' }}</p>
                <p class="gradeIconBox" v-if="selfRankInfo.user2">
                  <span :class="['vip', 'vip' + selfRankInfo.user2.vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="selfRankInfo.user2.vipLv && selfRankInfo.user2.vipLv > 0">{{ selfRankInfo.user2.vipLv }}</span>
                    <span v-if="selfRankInfo.user2 && selfRankInfo.user2.wealthLv > 0" :class="['wealth', countClass(selfRankInfo.user2.wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                      <span>{{ selfRankInfo.user2.wealthLv }}</span>
                    </span>
                </p>
              </div>
          </div>
        </div>
        <div class="roomRankBox" v-show="guardFlag == 1">
          <ul class="roomListBox">
            <template v-if="roomRankList.length > 0">
              <li v-for="(item) in roomRankList" :key="item.roomId" @click="toAppRoom(item)">
                <div class="roomRankNum">{{ item.top }}</div>
                <div class="roomInfoMsg">
                  <div class="roomIconBox">
                    <img :src="item.headIcon" alt="">
                    <span class="liveIcon" v-if="item.micState == 1"></span>
                  </div>
                  <div class="roomUserAll">
                    <p class="roomName">{{ item.name }}</p>
                    <ul class="roomUserIcon" v-if="item.tops">
                      <li v-for="(userItem,index) in item.tops" :key="index" @click.stop="skipUserInfo(userItem)">
                        <img :src="userItem.headIcon" alt="">
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="roomCpNum">{{ Number(item.score).toLocaleString('en-US') }}</div>
              </li>
            </template>
            <template v-else>
              <li class="ranListNull"></li>
            </template>
          </ul>
        </div>
      </div>
     </div>
     <!-- 弹窗 -->
     <div class="popBox" v-show="rulesPop > 0">
      <!-- guard规则 -->
      <div class="rulesPop" v-if="rulesPop == 1">
        <div class="rulesPopoClose" @click="rulesPopChange(0)"></div>
        <header class="rulesPopHead"></header>
        <div class="rulesPopContent">
          <p>{{ $t('valentine_tab2_rule1') }}</p>
          <p>{{ $t('valentine_tab2_rule2') }}</p>
        </div>
      </div>
      <!-- cp规则 -->
      <div class="rulesPop" v-if="rulesPop == 3">
        <div class="rulesPopoClose" @click="rulesPopChange(0)"></div>
        <header class="rulesPopHead"></header>
        <div class="rulesPopContent">
          <p>{{ $t('valentine_tab1_honeytrip_rule1') }}</p>
          <p>{{ $t('valentine_tab1_honeytrip_rule2') }}</p>
        </div>
      </div>
      <!-- 切换cp弹窗 -->
      <div class="changeCpPop" v-show="rulesPop == 2">
        <header></header>
        <div class="changeCpList">
          <div class="scrollBox" v-if="actInfo">
            <div class="selectCpList" :class="item.user2.uid == showCpInfo.user2.uid ? 'selectCpAct' : ''" v-for="(item,index) in actInfo.cpList" :key="index" @click="changeCpInfo(item)">
              <div class="cpIconBox">
                <img :src="item.user2.headIcon" alt=""  @click="skipUserInfo(item.user2)">
              </div>
              <div class="userInfoBox">
                <p>{{ item.user2 ? item.user2.name : '' }}</p>
                <p>{{ item.score }}</p>
              </div>
              <div class="dui" v-if="item.user2.uid == showCpInfo.user2.uid"></div>
            </div>
          </div>
          
        </div>
        <div class="rulesPopoClose" @click="rulesPopChange(0)"></div>
      </div>
      <!-- 领取奖励弹窗 -->
      <div class="getGiftPop" v-if="rulesPop == 4">
        <div class="rulesPopoClose" @click="rulesPopChange(0)"></div>
        <header></header>
        <div class="giftContent" v-if="levelIndex != 4">
            <div class="giftImg">
              <img :src="journeyLoveRewardShow['giftImg' + sex]" alt="">
            </div>
            <p class="giftName">{{ journeyLoveRewardShow['giftName' + sex] }}</p>
          <!-- <p class="giftNum">+500</p> -->
          <div class="giftBtnBox">
            <!-- 领取按钮 -->
            <div v-if="getRewardsInfo.state == 1 && levelIndex != 4" @click="getGiftBtn">{{ $t('valentine_preview_claim') }}</div>
            <!-- 已领取 -->
            <div v-if="getRewardsInfo.state == 2 && levelIndex != 4" class="notGet">{{ $t('valentine_preview_claimed') }}</div>
            <!-- 不能领取 -->
            <div v-if="getRewardsInfo.state == 0" class="notGet">{{ $t('valentine_preview_unlocked') }}</div>
          </div>
        </div>
        <div class="giftContent giftContent4" v-else>
            <div class="giftImgBox">
              <div class="giftImg">
                <img :src="journeyLoveRewardShow['giftImg' + sex]" alt="">
              </div>
              <div class="giftImg">
                <img v-if="levelIndex == 4" :src="journeyLoveRewardShow['giftImg3']" alt="">
              </div>
            </div>
            <p class="giftName giftName4">
              <span>{{ journeyLoveRewardShow['giftName' + sex] }}</span>
              <span>{{ journeyLoveRewardShow.giftName3 }}</span>
            </p>
          <!-- <p class="giftNum">+500</p> -->
          <div style="font-size: 14px;line-height: 20px;color: #000;text-align: center;">{{ $t('valentine_reward_send') }}</div>
          <div class="giftBtnBox">
            <!-- 不能领取 -->
            <div v-if="getRewardsInfo.state == 0" class="notGet">{{ $t('valentine_preview_unlocked') }}</div>
          </div>
        </div>
      </div>
      <!-- 规则弹窗 -->
      <div class="rewardsPopBox" v-if="rulesPop == 5">
        <header class="rewardsTitle"></header>
        <Rewards :region="region"/>
        <div class="rulesPopoClose" @click="rulesPopChange(0)"></div>
      </div>
     </div>
     <div class="loadingBox" v-if="loadingFlag">
          <van-loading type="spinner" size="50px"/>
      </div>
  </div>
 
</template>

<script>
import { Toast } from "vant";
import Rewards from './rewards.vue'
export default {
  name: "cpEvent",
  data() {
    return{
      days:0,
      hours:0,
      minutes:0,
      seconds:0,
      // 活动状态 0未开始 1已开始 2已结束
      actStatus:0,
      // 活动信息
      actInfo:null,
      curIndex:0,
      rewards:[],
      getRewardsInfo:{},
      // 展示的CP信息
      showCpInfo:null,
      // 判断平台
      client:'',
      // 选择日期展示
      dateSelectFlag: 0,
      // guard tab切换
      guardFlag: 0,
      // tab切换
      tabFlag: 0,
      rulesPop: 0,
      // 避免重复请求
      notFast: true,
      // 避免重复请求
      sendFlag: true,
      // cp榜单数据
      rankListData:[],
      // 房间榜单数据
      roomRankList:[],
      selfRankInfo:null,
      page: 0,
      size: 20,
      total:0,
      // loading是否展示
      loadingFlag:false,
      // 当前日期
      day:20250214,
      year: 2025,
      // 活动时间
      actTimeArr:['0214','0215','0216','0217','0218','0219','0220','0221','0222','0223'],
      actTimeArrActIndex:0,
      // 当前天
      actDay:'0000',
      // 防止重复领取
      noRepeat:true,
      // 大区id
      region:'',
      selfHeadIcon:'',
      selfName:'',
      // 奖励弹窗展示的数据
      journeyLoveRewardShow:{},
      journeyLoveReward:[
        {
          giftImg1:require('@/assets/cpEvent/level1.png'),
          giftImg2:require('@/assets/cpEvent/level1_1.png'),
          giftName1:this.$t('valentine_frame1'),
          giftName2:this.$t('valentine_frame1_female')
        },{
          giftImg1:require('@/assets/cpEvent/level2.png'),
          giftImg2:require('@/assets/cpEvent/level2_1.png'),
          giftName1:this.$t('valentine_chatbubble'),
          giftName2:this.$t('valentine_chatbubble')
        },{
          giftImg1:require('@/assets/cpEvent/level3.png'),
          giftImg2:require('@/assets/cpEvent/level3_1.png'),
          giftName1:this.$t('valentine_entranceeffect'),
          giftName2:this.$t('valentine_entranceeffect')
        },{
          giftImg1:require('@/assets/cpEvent/level4.png'),
          giftImg2:require('@/assets/cpEvent/level4_1.png'),
          giftName1:this.$t('valentine_frame2'),
          giftName2:this.$t('valentine_frame2_female')
        },{
          giftImg1:require('@/assets/cpEvent/level5.png'),
          giftImg2:require('@/assets/cpEvent/level5_1.png'),
          giftImg3:require('@/assets/cpEvent/level5rules.png'),
          giftName1:this.$t('valentine_cpidentification'),
          giftName2:this.$t('valentine_cpidentification'),
          giftName3:this.$t('valentine_cpwall'),
        },
      ],
      // 一天当中的倒计时
      dayDownTime:'00:00:00',
      dayDownTimer:null,
      nowDay:true,
      // 男女
      sex:1,
      // 奖励阶段
      levelIndex:0

    }
  },
  created() { 
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // 判断平台
    this.judgeClient()
    // this.getUserInfo() 
  },
  filters:{
    formatNumber(num) {
      if (num > 1000000) {
          return Math.trunc((num / 1000000) * 10) / 10 + 'M';
      } else if (num >= 1000) {
          return Math.trunc((num / 1000) * 10) / 10 + 'K';
      } else {
          return num.toString ();
      }
    },
    insertSlashInMiddle(str) {
        if (str.length === 0) return '/'; // 如果字符串为空，则直接返回斜杠
        // 计算插入点，使用Math.floor确保我们得到的是整数部分
        const insertPosition = Math.floor(str.length / 2);
        // 使用substring方法截取原字符串，并在计算出的位置插入斜杠
        return str.substring(0, insertPosition) + '/' + str.substring(insertPosition);
    }
  },
  components:{
    Rewards
  },
  methods: {
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang, 
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
    // this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt6TWpJNCIsInh5eiI6IlEwWkRRakJCTURBdFJEYzJOaTAwTURZMkxUaEJRamd0UkVaR01rVXhSRFpCUWpjMSIsImV4cCI6MTk5MjkzODIyNn0.-8-fxCL-8HULEkCIUdI9AaLZEBYdDwi9ZHC3cjhxa5Y';
    //   this.uid = '793228'; 
    //   this.headers = {
    //       fid: '1001',
    //       os: 1,
    //       lang: 'zh-CN',
    //       version: '3.0',
    //       deviceid: 'CFCB0A00-D766-4066-8AB8-DFF2E1D6AB75',
    //   }
    //   let langs = 'tr'
    //   this.langs = langs
    //   let lang = 'zh_CN'
      document.title = this.$t('valentine_title')
      if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      this.getActInfo();
      this.getTimeDay();
    },
    // 天倒计时
    updateCountdown() {
      this.dayDownTimer = setInterval(()=>{
        const now = new Date(); // 当前时间
        const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 24, 0, 0); // 今天的结束时间
        const timeDifference = endOfDay.getTime() - now.getTime(); // 时间差，毫秒为单位
        if (timeDifference > 0) {
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
            this.dayDownTime = `${hours < 10? '0' + hours : hours}:${minutes < 10? '0' + minutes : minutes}:${seconds < 10? '0' + seconds : seconds}`;
        } else {
            clearInterval(this.dayDownTimer)
            this.nowDay = false;
            this.dayDownTime = '00:00:00';
        }
      },1000)
  },
    // 切换cp
    changeCpInfo(item){
      this.showCpInfo = item;
      this.rulesPopChange(0)
      let personLeveldatas = {
            option: "get",
            host:
              this.$serviceIpJava +
              "api/activity/valentine/changeCp?token=" +
              this.token +
              "&uid2=" +
              this.showCpInfo.user2.uid +
              "&uid=" +
              this.uid,
            data: "",
            lang: this.langs,
            header: this.headers,
          };
          this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
            if (data.code == 200) {
              this.curIndex = data.data.curIndex;
              this.rewards = data.data.rewards;
            }else {
              Toast(data.message || this.$t('failed'))
            }
          });
    },
    // 领取奖励
    getGiftBtn(){
      if(this.noRepeat){
        this.noRepeat = false;
        let personLeveldatas = {
            option: "get",
            host:
              this.$serviceIpJava +
              "api/activity/valentine/reward?token=" +
              this.token +
              "&id=" +
              this.getRewardsInfo.id +
              "&uid2=" +
              this.showCpInfo.user2.uid +
              "&uid=" +
              this.uid,
            data: "",
            lang: this.langs,
            header: this.headers,
          };
          this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
            if (data.code == 200) {
              this.rulesPopChange(0);
              this.getRewardsInfo.state = 2;
              Toast(this.$t('valentine_successful'))
              this.noRepeat = true;
            }else {
              Toast(data.message || this.$t('failed'))
            }
          });
      }
      
    },
    //分页
    pageScroll(e) {
      if(this.tabFlag == 1){
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        let num = this.client == 'Android' ? 50 : 0;
        // 检查是否滚动到底部
        if (scrollTop + clientHeight >= (scrollHeight - num)) {
            if(this.notFast){
                this.page++;
                if(this.guardFlag == 0){
                  this.getRankList()
                }else{
                  this.getRoomRankList()
                }
            }
        }
      }
      
    },
    // 获取活动信息
    getActInfo(){
      // 获取活动信息
      let personLeveldatas = {
          option: "get",
          host:
            this.$serviceIpJava +
            "api/activity/valentine/init?token=" +
            this.token +
            "&uid=" +
            this.uid,
          data: "",
          lang: this.langs,
          header: this.headers,
        };
        this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
          if (data.code == 200) {
            this.actInfo = data.data;
            this.showCpInfo = data.data.cpList[0];
            this.curIndex = this.actInfo.curIndex;
            this.rewards = data.data.rewards;
            this.region = this.actInfo.region;
            this.selfHeadIcon = this.actInfo.headIcon;
            this.selfName = this.actInfo.name;
            this.sex = this.actInfo.sex;
            this.countActStatus(data.data)
          }else {
            Toast(data.message || this.$t('failed'))
          }
        });
    },
    // 计算活动状态
    countActStatus(data){
      let newTime = new Date().getTime();
      // 活动未开始
      if(newTime < data.startTime){
        this.actStatus = 0;
        this.finalsDownTime(data.startTime)
        // 活动进行中
      }else if(newTime > data.startTime && newTime < data.stopTime){
        this.actStatus = 1;
        this.finalsDownTime(data.stopTime);
        this.updateCountdown()
        // 活动已结束
      }else{
        this.actStatus = 2;
      }
    },
    // 切换Tab展示
    changeTab(type){
      this.tabFlag = type;
      this.guardFlag = 0;
      if(type == 1){
        this.getTimeDay();
        this.updateCountdown();
        this.nowDay = true;
        // 获取CP榜单
        this.getRankList();
        // 获取自己的榜单信息
        this.getSelfRankInfo();
      }
    },
    // 获取cp榜单
    getRankList(){
      this.loadingFlag = true;
      this.notFast = false;
        // 获取活动时间
        let personLeveldatas = {
          option: "get",
          host:
            this.$serviceIpJava +
            "api/activity/valentine/role/page?token=" +
            this.token +
            "&uid=" +
            this.uid +
            "&page=" +
            this.page +
            "&size=" +
            this.size +
            "&day=" +
            this.day,
          data: "",
          lang: this.langs,
          header: this.headers,
        };
      if(this.sendFlag){
        this.sendFlag = false;
        this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
          this.loadingFlag = false;
          if (data.code == 200) {
            this.rankListData.push(...data.data.list);
            //  是否还有下一页
            if(data.data.list.length == data.data.size){
              this.notFast = true
            }
          }else {
            Toast(data.message || this.$t('failed'))
          }
          this.sendFlag = true;
        });
      }
    },
    // 获取房间榜单
    getRoomRankList(){
      this.loadingFlag = true;
      this.notFast = false;
        // 获取活动时间
        let personLeveldatas = {
          option: "get",
          host:
            this.$serviceIpJava +
            "api/activity/valentine/room/page?token=" +
            this.token +
            "&uid=" +
            this.uid +
            "&page=" +
            this.page +
            "&size=" +
            this.size,
          data: "",
          lang: this.langs,
          header: this.headers,
        };
      if(this.sendFlag){
        this.sendFlag = false;
        this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
          this.loadingFlag = false;
          if (data.code == 200) {
            this.roomRankList.push(...data.data.list);
            //  是否还有下一页
            if(data.data.list.length == data.data.size){
              this.notFast = true
            }
          }else {
            Toast(data.message || this.$t('failed'))
          }
          this.sendFlag = true;
        });
      }
    },
    // 获取自己当前排名信息
    getSelfRankInfo(){
        // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/valentine/role/rank?token=" +
          this.token +
          "&uid=" +
          this.uid +
          "&day=" +
          this.day,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
         this.selfRankInfo = data.data;
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
    },
    // 倒计时
    finalsDownTime(endTime) {
      this.timer = setInterval( ()=> {
        const now = new Date().getTime();
        const distance = endTime - now;
        if (distance < 0) {
          clearInterval(this.timer);
          this.actStatus = 2;
          return;
        }
        const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 * 24)));
        const hours = this.zeroFill(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = this.zeroFill(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((distance % (1000 * 60)) / 1000));

        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
      }, 1000);
    },
    zeroFill(num){
      if(num < 10){
        return '0' + num;
      }else{
        return num
      }
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    // 展示与关闭日期选择
    changeDateSelect(type,item = ''){
      this.dateSelectFlag = type;
      if(type == 0){
        this.actDay = item;
        this.day = Number(this.year + item);
        this.rankListData = [];
        this.page = 0;
        this.getRankList();
        // 获取自己的榜单信息
        this.selfRankInfo = null;
        this.getSelfRankInfo();
        if('2025' + item == this.monthDay()){
          this.nowDay = true;
        }else{
          this.nowDay = false;
        }
      }
    },
    monthDay(){
      // 创建一个新的Date对象，代表当前时间
      const now = new Date();
      // 获取月份，因为月份是从0开始计数，所以这里需要加1
      const month = now.getMonth() + 1;
      // 获取日
      const day = now.getDate();
      return '2025' + String(month).padStart(2, '0') + String(day).padStart(2, '0');
    },
    // 切换展示guardTab
    changeGuardFlag(type){
      this.guardFlag = type;
      this.page = 0;
      this.roomRankList = [];
      this.rankListData = [];

      if(this.guardFlag == 0){
        this.getTimeDay();
        this.updateCountdown();
        this.nowDay = true;
        this.getRankList()
      }else{
        this.getRoomRankList()
      }
    },
    // 规则弹窗
    rulesPopChange(type,item = {},index=0){
      this.rulesPop = type;
      if(type == 4){
        this.levelIndex = index;
        this.getRewardsInfo = item;
        this.journeyLoveRewardShow = this.journeyLoveReward[index];
      }
      if(type == 0){
        this.canScroll();
      }else{
        this.stopScroll()
      }
    },
    // 动态计算class¸¸
    countClass(levele){
      let className = '';
      if(levele > 0 && levele < 15){
        className = 'wealth1_15';
      }else if(levele > 15 && levele <= 30){
        className = 'wealth16_30';
      }else if(levele > 30 && levele <= 45){
        className = 'wealth31_45';
      }else if(levele > 45 && levele <= 60){
        className = 'wealth46_60';
      }else if(levele > 60 && levele <= 75){
        className = 'wealth61_75';
      }else if(levele > 75 && levele <= 99){
        className = 'wealth76_99';
      }else if(levele > 99 && levele <= 109){
        className = 'wealth100_109';
      }else if(levele > 109 && levele <= 119){
        className = 'wealth110_119';
      }else if(levele > 119 && levele <= 129){
        className = 'wealth120_129';
      }else if(levele > 129 && levele <= 139){
        className = 'wealth130_139';
      }else if(levele > 139 && levele <= 149){
        className = 'wealth140_149';
      }else if(levele > 149 && levele <= 159){
        className = 'wealth150_159';
      }else if(levele > 159 && levele <= 169){
        className = 'wealth160_169';
      }else if(levele > 169 && levele <= 179){
        className = 'wealth170_179';
      }else if(levele > 179 && levele <= 189){
        className = 'wealth180_189';
      }else if(levele > 189 && levele <= 200){
        className = 'wealth190_200';
      }
      // this.wealthArr.push(className);
      return className;
    },
    // 获取当天时间
    getTimeDay(){
      // 创建一个新的Date对象，它会自动初始化为当前的日期和时间
      const today = new Date();
      // 获取年份
      const year = today.getFullYear();
      // 获取月份，注意：JavaScript中的月份是从0开始计数的（即0代表1月），所以需要加1
      const month = String(today.getMonth() + 1).padStart(2, '0');
      // 获取一个月中的哪一天
      const day = String(today.getDate()).padStart(2, '0');
      console.log(day)
      // 格式化日期为 YYYY-MM-DD 的形式
      this.day = `${year}${month}${day}`;
      this.year = year;
      this.actDay = `${month}${day}`;
      // 截取日期数组
      if(this.actTimeArr.indexOf(this.actDay) != -1){
        this.actTimeArr = this.actTimeArr.slice(0,(this.actTimeArr.indexOf(this.actDay) + 1));
      }else{
        this.actDay = this.actTimeArr[this.actTimeArr.length-1];
        this.day = year + this.actDay
      }

    },
    // 跳转房间
    toAppRoom(item){
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['languageRoom'].postMessage(JSON.stringify(item.roomId));
      } else if (this.client == 'Android') {
        nativeJs["languageRoom"](JSON.stringify(item.roomId));
      }
    },
    // 跳转资料页  需要uid
    skipUserInfo(item) {
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.uid));
      } else if (this.client == 'Android') {
        nativeJs["skipUserInfo"](JSON.stringify(item.uid));
      }
    },
  }
};
</script>

<style scoped>
@import './index.css';
@import '../../assets/gradeIcon/gradeIcon.css';
</style>
